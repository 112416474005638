jQuery(function ($) {

	var TTFU_Scripts = {

		debounce: function (func, wait, immediate) {
			let timeout;
			return function executedFunction() {
				const context = this;
				const args = arguments;
				const later = function () {
					timeout = null;
					if (!immediate) func.apply(context, args);
				};
				const callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) func.apply(context, args);
			};
		},

		headerScripts: function () {
			$('.burger-btn').click(function () {
				$('body').toggleClass('menu-open');
				$(this).toggleClass('active');
			});
			// Header Search
			let searchForm = $('.header__search-wrap');
			$('.header__search-btn').click(function () {
				$('body').addClass('search-open');
				$('.search-form__input').select();
			});

			$('.header__search-close').click(function () {
				$('body').removeClass('search-open');
			});
		},

		filters: function () {
			var dateFilter = $('#filter-date');
			if (dateFilter.length) {
				TTFU_Scripts._datePicker();
			}

			var filter = $('.filter');
			var resetOneFilter = $('.filter__active--reset');
			var resetAllFilters = $('.filter-reset');
			var filterItem = $('.filter-list-item');
			var filterTypesItem = $('.filter-types__item');
			var menuItems = $('.header__nav #menu-primary .sub-menu .menu-item, .mobile__nav #menu-publications .menu-item, .footer__nav .menu-footer .menu-item');


			filter.click(function () {
				if ($(this).hasClass('filter-open')) {
					$(this).removeClass('filter-open');
					$(document).off('click.filterDropdown');
				} else {
					filter.removeClass('filter-open');
					$(document).off('click.filterDropdown');
					$(this).addClass('filter-open');
					$(document).on('click.filterDropdown', function (e) {
						if (!filter.is(e.target) && filter.has(e.target).length === 0) {
							filter.removeClass('filter-open');
							setTimeout(function () {
								$(document).off('click.filterDropdown');
							}, 500);
						};
					});
				}
				TTFU_Scripts._dropdownPosition($(this));
			});
			$('.filter-dropdown, .daterangepicker').click(function () {
				event.stopPropagation();
			});

			resetOneFilter.click(function () {
				event.stopPropagation();
				filter.removeClass('filter-open');
				$('.filter-list-item--' + $(this).data('reset')).removeClass('active');
				$('.filter-list-item--' + $(this).data('reset') + '[data-filter="all"]').addClass('active');
				TTFU_Scripts._getFilterPosts();
			});
			$('.filter__active--date').click(function () {
				event.stopPropagation();
				dateFilter.attr('data-first', '');
				dateFilter.attr('data-last', '');
				dateFilter.data('daterangepicker').setStartDate(moment());
				dateFilter.data('daterangepicker').setEndDate(moment());
				dateFilter.data('daterangepicker').hide();
				TTFU_Scripts._getFilterPosts();
			});

			filterItem.click(function () {
				$(this).siblings().removeClass('active');
				$(this).addClass('active');
				TTFU_Scripts._getFilterPosts();
				TTFU_Scripts._dropdownPosition($('.filter.filter-open'));
			});

			filterTypesItem.click(function () {
				filterTypesItem.removeClass('active');
				menuItems.removeClass('current-menu-item');
				$(this).addClass('active');
				TTFU_Scripts._getFilterPosts();
			});

			resetAllFilters.click(function () {
				filterItem.removeClass('active');
				$('.filter-list-item[data-filter="all"]').addClass('active');
				if (dateFilter.length) {
					dateFilter.attr('data-first', '');
					dateFilter.attr('data-last', '');
					dateFilter.data('daterangepicker').setStartDate(moment());
					dateFilter.data('daterangepicker').setEndDate(moment());
					dateFilter.data('daterangepicker').hide();
				}
				TTFU_Scripts._getFilterPosts();
			});
		},

		_dropdownPosition: function (elem) {
			if ((elem.offset.left + 265) > $(window).width()) {
				var right = $(window).width() - (elem.offset.left + 265);
				elem.find('.filter-dropdown').css("left", right + "px");
			} else {
				elem.find('.filter-dropdown').css("left", "0");
			}
		},

		_datePicker: function () {
			var dateFilter = $('#filter-date');

			var langArray = {
				'en': {
					'daysOfWeek': [
						'Su',
						'Mo',
						'Tu',
						'We',
						'Th',
						'Fr',
						'Sa'
					],
					'monthNames': [
						'January',
						'February',
						'March',
						'April',
						'May',
						'June',
						'July',
						'August',
						'September',
						'October',
						'November',
						'December'
					],
					'btn': [
						'Cancel',
						'Apply'
					]
				},
				'uk': {
					'daysOfWeek': [
						'Нд',
						'Пн',
						'Вт',
						'Ср',
						'Чт',
						'Пт',
						'Сб'
					],
					'monthNames': [
						'Січень',
						'Лютий',
						'Березень',
						'Квітень',
						'Травень',
						'Червень',
						'Липень',
						'Серпень',
						'Вересень',
						'Жовтень',
						'Листопад',
						'Грудень'
					],
					'btn': [
						'Скинути',
						'Застосувати'
					]
				}
			}

			dateFilter.daterangepicker({
				startDate: (dateFilter.attr("data-first")) ? dateFilter.attr("data-first") : moment(),
				endDate: (dateFilter.attr("data-last")) ? dateFilter.attr("data-last") : moment(),
				autoUpdateInput: false,
				locale: {
					format: "YYYY-MM-DD",
					cancelLabel: langArray[lang]['btn'][0],
					applyLabel: langArray[lang]['btn'][1],
					daysOfWeek: langArray[lang]['daysOfWeek'],
					monthNames: langArray[lang]['monthNames'],
				},
				alwaysShowCalendars: true,
				showCustomRangeLabel: false,
				parentEl: '#filter-date',
				linkedCalendars: false,
			});

			dateFilter.on('cancel.daterangepicker', function (ev, picker) {
				$(this).attr('data-first', '');
				$(this).attr('data-last', '');
				$(this).data('daterangepicker').setStartDate(moment());
				$(this).data('daterangepicker').setEndDate(moment());
				TTFU_Scripts._getFilterPosts();
			});
			dateFilter.on('apply.daterangepicker', function (ev, picker) {
				$(this).attr('data-first', picker.startDate.format('YYYY-MM-DD'));
				$(this).attr('data-last', picker.endDate.format('YYYY-MM-DD'));
				TTFU_Scripts._getFilterPosts();
			});
		},

		_getFilterParams: function () {
			var filterParam = [];

			filterParam['types'] = [];
			filterParam['types'] = $('.filter-types__item.active').data('filter');

			filterParam['tags'] = [];
			filterParam['tags'] = $('.filter-list-item--tag.active').data('filter');

			filterParam['authors'] = [];
			filterParam['authors'] = $('.filter-list-item--author.active').data('filter');

			filterParam['date-first'] = $('#filter-date').attr('data-first');
			filterParam['date-last'] = $('#filter-date').attr('data-last');

			return filterParam;
		},

		_getFilterPosts: function () {
			var l = '';
			if (lang != 'en') {
				l = '/' + lang;
			}
			var parameters = '';
			var filterParams = TTFU_Scripts._getFilterParams();
			var reset = false;

			if (filterParams['types'] == 'all')
				$('a[href="https://transatlantic.rpr.org.ua/publications/"]').parent('.menu-item').addClass('current-menu-item');
			else
				$('a[href="/publications/?publication-type=' + filterParams['types'] + '"]').parent('.menu-item').addClass('current-menu-item');

			if (filterParams['types'] && filterParams['types'] != 'all') {
				parameters += '&publication-type=' + filterParams['types'];
			}

			if (filterParams['tags'] && filterParams['tags'] != 'all') {
				parameters += '&publication-tags=' + filterParams['tags'];
				$('.filter__active--tag').addClass('show');
				reset = true;
			} else {
				$('.filter__active--tag').removeClass('show');
			}

			if (filterParams['authors'] && filterParams['authors'] != 'all') {
				parameters += '&authors=' + filterParams['authors'];
				$('.filter__active--author').addClass('show');
				reset = true;
			} else {
				$('.filter__active--author').removeClass('show');
			}

			if (filterParams['date-first']) {
				parameters += '&date-start=' + filterParams['date-first'];
				$('.filter__active--date').addClass('show');
				reset = true;
			} else {
				$('.filter__active--date').removeClass('show');
			}
			if (filterParams['date-last']) {
				parameters += '&date-finish=' + filterParams['date-last'];
			}

			if (reset) {
				$('.filter-reset').addClass('show');
			} else {
				$('.filter-reset').removeClass('show');
			}

			if (parameters.length) {
				parameters = '?' + parameters.slice(1);
			}

			history.pushState('', '', window.location.origin + l + '/publications/' + parameters);

			var data = {
				'action': 'get_filter_posts',
				'types': filterParams['types'],
				'tags': filterParams['tags'],
				'authors': filterParams['authors'],
				'date-first': filterParams['date-first'],
				'date-last': filterParams['date-last'],
				'urlParam': parameters
			};
			$.ajax({
				url: ajaxurl,
				data: data,
				type: 'POST',
				beforeSend: function (xhr) {
					$('.loader-wrap').removeClass('hide');
				},
				complete: function () {
					$('.loader-wrap').addClass('hide');
				},
				success: function (data) {
					let res = JSON.parse(data);
					if (res.posts !== '') {
						$('.publications-wrap').html(res.posts);
					}
					$('.pagination').html(res.pagination);
				}
			});
		},

		loadmorePublications: function () {
			var button = $('#loadmore_publications'),
				paged = button.data('paged'),
				maxPages = button.data('max_pages');

			button.click(function (e) {

				var button = $(this),
					data = {
						'action': 'loadmore_publications',
						'paged': paged,
						'first': button.data('first')
					};
				console.log(data);
				$.ajax({
					url: ajaxurl,
					data: data,
					type: 'POST',
					beforeSend: function (xhr) {
						$('.loader-wrap').removeClass('hide');
					},
					complete: function () {
						$('.loader-wrap').addClass('hide');
					},
					success: function (data) {
						if (data) {
							let res = JSON.parse(data);
							if (res.posts !== '') {
								$('.publications-wrap').append(res.posts);
							}
							paged++;
							if (paged == maxPages)
								button.addClass('disabled');
						}
					}
				});
			});
		},

		init: function () {
			TTFU_Scripts.headerScripts();
			if ($('.filters').length) {
				TTFU_Scripts.filters();
			}
			TTFU_Scripts.loadmorePublications();
		}

	};

	$(window).resize(function () {
		if ($('#filter-date').length) {
			TTFU_Scripts._datePicker();
			TTFU_Scripts._dropdownPosition($('.filter.filter-open'));
		}
	});

	$(document).ready(function () {
		$('.current-menu-item').siblings('.menu-item-object-publications.current-menu-item').removeClass('current-menu-item');
		TTFU_Scripts.init();
	});

});